import { useState, useEffect } from "react";
import VideoList from "./VideoList";
import useFetch from "./useFetch";
const isPending = false;

const Home = () => {
    
    // const [videos, setVideos] = useState([
    //     { title: 'AWS: Intro - Creare un account AWS', body: 'lorem ipsum...', author: 'mario', youtubeId: "iBaheYtd5Jc", id: 1 },
    //     { title: 'AWS IAM: Creare un utente', body: 'lorem ipsum...', author: 'yoshi', youtubeId: "m7N7GbFAiDs", id: 2 },
    //     { title: 'Amazon EC2: Creare istanza EC2', body: 'lorem ipsum...', author: 'mario', youtubeId: "DeM1XssZv5Q", id: 3 },
    //     { title: 'AWS SAM: Applicazioni serverless', body: 'lorem ipsum...', author: 'mario', youtubeId: "m7N7GbFAiDs", id: 4 }
    //   ])

    const { error, isPending, data: videos } = useFetch('https://qppd4w2ij1.execute-api.eu-south-1.amazonaws.com/fetch')

    return ( 
        <div className="home">

           {/* {isPending && <div>Loading ... </div>}
           
            <VideoList videos={videos.filter((video) => video.id <= 2)} title="Per iniziare ... " />
            <VideoList videos={videos.filter((video) => video.id > 2)} title="LAB" /> */}
            { error && <div>{ error }</div> }
            { isPending && <div>Loading...</div> }
            { videos && <VideoList videos={videos} /> }
        </div>

     );
}
 
export default Home;