import { useState, useEffect } from "react";

const useFetch = (url) => {

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] =useState(null);
    
    useEffect(() => {
        //setTimeout(() => { //esegue il codice dopo un secondo
            fetch('https://qppd4w2ij1.execute-api.eu-south-1.amazonaws.com/fetch')
            .then(res =>{
                if(!res.ok){
                    throw Error('could not fetch the data for that resource');
                }
                return res.json();
            })
            .then(data => {
                setData(data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                // console.log(err.message);
                setIsPending(false);
                setError(err.message);
            })
       // }, 1000)
    }, [url])

    return {data, isPending, error}

}
 
export default useFetch;