import Youtube from "react-youtube";

const opts = {
    height: "350",
    width: "250",
    playerVars: {
      autoplay: 1,
    },
};

// const VideoList = (props) => {
const VideoList = ({videos, title}) => {
    // const videos = props.videos;
    // const title = props.title;

    // console.log(props, videos);
  
    return (

        <div className="video-list">
            <h2>{title}</h2>
            {videos.map((video) => (
                <div className="video-preview" key={video.id}>
                    <h2>{video.title}</h2> 
                    <Youtube videoId={video.youtubeId} opt={opts} />
                </div>
            ))}
        </div>
    );


}

export default VideoList;